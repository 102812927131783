import { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';

function App() {

  const [played, setPlayed] = useState(false);
  const [gain, setGain] = useState(null);

  const [bacgroundPosition1, setBackgroundPosition1] = useState(0);
  const [bacgroundPosition2, setBackgroundPosition2] = useState(0);
  const [bacgroundPosition3, setBackgroundPosition3] = useState(0);

  const loadGains = async () => {
    setPlayed(true);
    try {
      const formData = new URLSearchParams();
      formData.append('input[game_id]', 'c498e0db-b155-4ea7-9504-33d314a7cba7');
      formData.append('input[lang]', 'fr');
      formData.append('input[player_data]', JSON.stringify({}));  // Si vous avez des données supplémentaires
      
      const response = await axios.post('https://api-gain.web-treeosk.online/play', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      setTimeout(()=>{
        setGain(response.data.data); // Traiter la réponse si succès
      }, 8600);
      switch(response.data.data.id){
        case '9a30d099-0c08-4dfa-a31a-0260a2735c9b':
          animationLoose();
          break;
        case 'e661be3b-34ba-4944-a860-79fed5d23c6c':
          animationTrousse();
          break;
        case 'e1f2ad88-190a-453f-a167-13c857f55f2b':
          animationGel();
          break;
        case '4a224b20-9ae9-4fb0-853a-94e448ad36d0':
          animationWecandoo();
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Erreur lors de l'appel API:", error); // Capturer et loguer l'erreur
    }
  };  

  const animationLoose = () => {
    setBackgroundPosition1(randomInt(500, 800));
    setBackgroundPosition2(randomInt(500, 800));
    setBackgroundPosition3(randomInt(500, 800));
  }

  const animationTrousse = () => {
    setBackgroundPosition1(696);
    setBackgroundPosition2(694);
    setBackgroundPosition3(696);
  }

  const animationGel = () => {
    setBackgroundPosition1(720);
    setBackgroundPosition2(718);
    setBackgroundPosition3(720);
  }

  const animationWecandoo = () => {
    setBackgroundPosition1(708);
    setBackgroundPosition2(706);
    setBackgroundPosition3(708);
  }

  return (
    <>
      <div className="App" style={{width: '100%',height: '1780px', position: 'fixed', zIndex: 300}}>
      </div>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', height: '480px', marginTop: '480px', zIndex: 50, position: 'fixed', width: '460px', left: '290px'}}>
        <div className='lamelle lam1' style={{height: '100%', backgroundSize: 'cover', backgroundPositionY: bacgroundPosition1 + '%'}}>
  
        </div>
        <div className='lamelle lam2' style={{height: '100%', backgroundSize: 'cover', backgroundPositionY: bacgroundPosition2 + '%'}}>
  
        </div>
        <div className='lamelle lam3' style={{height: '100%', backgroundSize: 'cover', backgroundPositionY: bacgroundPosition3 + '%'}}>

        </div>
      </div>
      {
        <div className='bg' style={{opacity: (gain) ? 1 : 0,transitionDuration: '1000ms',display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', height: '500px', marginTop: '480px', zIndex: 800, position: 'fixed', width: '460px', left: '280px', backgroundSize: 'containt', background: (gain) ? 'url(' + gain.uri + ')' : 'white'}}>

        </div>
      }
      {
        !played ?
        <button onClick={loadGains} className='pulse' style={{position: 'absolute', width: '300px', fontSize: '48px', border: 'solid 6px white', background: 'black', color: 'white', left: '370px', padding: '20px', top: '700px', zIndex: 1000}}>
          JOUEZ!
        </button>
        :
        <></>
      }
    </>
  );
}

function randomInt(min, max){
  return Math.floor(Math.random() * (max - min) + min);
}

export default App;